exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-ask-dashboard-index-tsx": () => import("./../../../src/pages/ask/dashboard/index.tsx" /* webpackChunkName: "component---src-pages-ask-dashboard-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-log-in-tsx": () => import("./../../../src/pages/log-in.tsx" /* webpackChunkName: "component---src-pages-log-in-tsx" */),
  "component---src-pages-log-out-tsx": () => import("./../../../src/pages/log-out.tsx" /* webpackChunkName: "component---src-pages-log-out-tsx" */),
  "component---src-pages-logout-d-tsx": () => import("./../../../src/pages/logoutD.tsx" /* webpackChunkName: "component---src-pages-logout-d-tsx" */),
  "component---src-pages-my-dashboard-tsx": () => import("./../../../src/pages/my-dashboard.tsx" /* webpackChunkName: "component---src-pages-my-dashboard-tsx" */),
  "component---src-pages-my-profile-tsx": () => import("./../../../src/pages/my-profile.tsx" /* webpackChunkName: "component---src-pages-my-profile-tsx" */),
  "component---src-pages-recommendation-tsx": () => import("./../../../src/pages/recommendation.tsx" /* webpackChunkName: "component---src-pages-recommendation-tsx" */),
  "component---src-pages-sign-up-tsx": () => import("./../../../src/pages/sign-up.tsx" /* webpackChunkName: "component---src-pages-sign-up-tsx" */)
}

